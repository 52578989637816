<template>
  <main>
    <CAlert color="info" :fade="false">
      <i class="fas fa-info-circle mr-2" />設定優惠活動後，若購物車內商品達到設定之條件即會自動帶入優惠。
    </CAlert>
    <CForm>
      <CCard>
        <CAlert
          id="ChooseNotice"
          v-if="(ChooseID.length > 0)"
          color="info"
          :fade="false"
        >
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseID.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
          <div class="ButtonGroup">
            <CButton color="light" size="sm" class="mr-2" @click="ChooseID = [];CheckAllValue = false">{{ $t('Global.Cancel') }}</CButton>
            <CButton color="danger" size="sm" @click="AlertModel = true">{{ $t('Global.Delete') }}</CButton>
          </div>
        </CAlert>
        <CCardHeader>
          <CRow class="d-flex justify-content-between align-items-center">
            <CCol col="6">
              <h5 class="mb-0">{{ $t('Navigation.Offer/Activity') }}</h5>
            </CCol>
            <CCol col="6">
              <div class="card-header-actions d-flex">
                <CButton color="success" type="button" @click="$router.push('/offer/activity/add')">
                  {{ $t('Global.Add') + $t('Navigation.Offer/Activity') }}
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardHeader class="w-100 overflow-auto px-2">
          <CButton color="white" shape="pill" class="mr-2" @click="FilterModel = true;ShowFilter = 'All'">
            <CIcon name="cil-filter"/>
          </CButton>
          <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('DataTimeRange')">
            {{ $t('Offer.OfferTime') }}:
            {{ SearchFilter.StartTime && SearchFilter.EndTime ? `${$dayjs(SearchFilter.StartTime).format('YYYY-MM-DD HH:mm:ss')} ~ ${$dayjs(SearchFilter.EndTime).format('YYYY-MM-DD HH:mm:ss')}` : '所有時間區間' }}
            <CIcon size="sm" class="ml-1" name="cil-pencil"/>
          </CButton>
          <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Name')">
            {{ $t('Offer.Name') }}: {{ SearchFilter.Name || '--' }}
            <CIcon size="sm" class="ml-1" name="cil-pencil"/>
          </CButton>
          <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('OfferTarget')">
            {{ $t('Offer.Target') }}: {{ SearchFilter['Offer.Target'].map(item => item.label).join(',') || '--' }}
            <CIcon size="sm" class="ml-1" name="cil-pencil"/>
          </CButton>
          <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('OfferDiscountType')">
            {{ $t('Offer.DiscountType') }}: {{ SearchFilter['Offer.DiscountType'].map(item => item.label).join(',') || '--' }}
            <CIcon size="sm" class="ml-1" name="cil-pencil"/>
          </CButton>
          <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('UsagePageSlug')">
            {{ $t('Offer.UsagePage') }}: {{ SearchFilter['UsagePage.Slug'].map(item => item.label).join(',') || '--' }}
            <CIcon size="sm" class="ml-1" name="cil-pencil"/>
          </CButton>
          <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('TargetGroupType')">
            {{ $t('Offer.TargetGroup') }}: {{ SearchFilter['TargetGroup.Type'].map(item => item.label).join(',') || '--' }}
            <CIcon size="sm" class="ml-1" name="cil-pencil"/>
          </CButton>
          <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Status')">
            {{ $t('Offer.Status') }}: {{ SearchFilter.Status ? $t('Offer.StatusOptions.' + SearchFilter.Status) : '--' }}
            <CIcon size="sm" class="ml-1" name="cil-pencil"/>
          </CButton>
          <CButton size="sm" color="danger" shape="pill" class="mr-2" @click="ResetFilter()">
            <CIcon size="sm" name="cil-x"/>
          </CButton>
        </CCardHeader>
        <CCardBody class="p-0">
          <CDataTable
            id="CouponList"
            :items="List"
            :fields="Field"
            :loading="Loading"
            :noItemsView="noItemsView"
            responsive
            hover
          >
            <template #Checkbox-header>
              <label class="mb-0">
                <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
              </label>
            </template>
            <template #Checkbox="{item}">
              <td class="text-center">
                <label class="mb-0">
                  <input type="checkbox" :id="'Checkbox-Activity-' + item._id" :value="item._id" v-model="ChooseID">
                </label>
              </td>
            </template>
            <template #UsagePage="{item}">
              <td>
                {{ item.UsagePage.map(data => data.Name).join(',') }}
              </td>
            </template>
            <template #TargetGroup="{item}">
              <td>
                {{ $t('Offer.ActivityTargetGroupOptions.' + item.TargetGroup.Type) }}
              </td>
            </template>
            <template #TimeRange="{item}">
              <td>
                {{ $t('Offer.StartTime') }}: {{ $dayjs(item.StartTime).format('YYYY-MM-DD HH:mm:ss') }}<br>
                {{ $t('Offer.EndTime') }}: {{ $dayjs(item.EndTime).format('YYYY-MM-DD HH:mm:ss') }}
              </td>
            </template>
            <template #Status="{item}">
              <td>
                <CBadge :color="(item.Status === 'Y' ? 'success' : 'danger')">
                  {{ $t('Offer.StatusOptions.' + item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #Action="{item}">
              <td>
                <CButton color="info" size="sm" class="mr-1" v-c-tooltip="$t('Global.Edit')" @click="$router.push('/offer/activity/detail/' + item._id)">
                  <CIcon name="cil-pencil" class="c-icon-custom-size" />
                </CButton>
                <CButton color="danger" size="sm" class="mr-1" v-c-tooltip="$t('Global.Delete')" @click="DeleteModal(item._id)">
                  <CIcon name="cil-trash" class="c-icon-custom-size" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
        <CCardFooter>
          <CPagination
            :activePage.sync="Pagination.Page"
            :pages.sync="Pagination.TotalPages"
            @update:activePage="GetList()"
          />
        </CCardFooter>
      </CCard>
    </CForm>
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="false">
      <CAlert color="danger">
        <CIcon name="cil-bell" /> {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false;ChooseID = []" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false;ChooseID = []" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteSubmit" @click="Delete()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="FilterModel" class="ActionModel" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit">
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'DataTimeRange'" form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.StartTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.StartTime" mode="date" :max-date="$dayjs(SearchFilter.EndTime || new Date).subtract(1, 'day').toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.EndTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.EndTime" mode="date" :min-date="$dayjs(SearchFilter.StartTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Name'" v-model="SearchFilter.Name" :label="$t('Offer.Name')" type="text" horizontal />
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'OfferTarget'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Offer.Target') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Offer.Target"
            v-model="SearchFilter['Offer.Target']"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            label="label"
            track-by="value"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="OfferTargetOptions"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'OfferDiscountType'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Offer.DiscountType') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Offer.DiscountType"
            v-model="SearchFilter['Offer.DiscountType']"
            :optionHeight="24"
            label="label"
            track-by="value"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="OfferDiscountTypeOptions"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'UsagePageSlug'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Offer.UsagePage') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.UsagePage.Slug"
            v-model="SearchFilter['UsagePage.Slug']"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            label="label"
            track-by="value"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="PageList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'TargetGroupType'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Offer.TargetGroup') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.TargetGroup.Type"
            v-model="SearchFilter['TargetGroup.Type']"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            label="label"
            track-by="value"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="TargetGroupTypeOptions"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CSelect v-if="ShowFilter === 'All' || ShowFilter === 'Status'" horizontal :label="$t('Offer.Status')" :options="StatusOptions" v-model="SearchFilter.Status" :value.sync="SearchFilter.Status" :placeholder="$t('Global.PleaseSelect')"/>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="GetList()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "優惠活動"
}
}
</route>

<script>
export default {
  name: 'ActivityList',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect')
  },
  data () {
    return {
      List: [],
      ChooseID: [],
      CheckAllValue: false,
      AlertModel: false,
      SearchFilter: {
        StartTime: '',
        EndTime: '',
        Name: '',
        Slug: [],
        'Offer.Target': [],
        'Offer.DiscountType': [],
        'UsagePage.Slug': [],
        'TargetGroup.Type': [],
        Status: ''
      },
      FilterModel: false,
      ShowFilter: 'All',
      PageList: [],
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Order: 'nto',
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      }
    }
  },
  computed: {
    Field () {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'Name', label: this.$t('Offer.Name') },
        { key: 'UsagePage', label: this.$t('Offer.UsagePage') },
        { key: 'TargetGroup', label: this.$t('Offer.TargetGroup') },
        { key: 'TimeRange', label: this.$t('Offer.TimeRange') },
        { key: 'Status', label: this.$t('Offer.Status') },
        { key: 'Action', label: '' }
      ]
    },
    StatusOptions() {
      return Object.keys(this.$t('Offer.StatusOptions')).filter(type => type !== 'E').map(type => {
        return {
          value: type,
          label: this.$t('Offer.StatusOptions')[type]
        }
      })
    },
    OfferTargetOptions() {
      return Object.keys(this.$t('Offer.TargetOptions')).map(type => {
        return {
          value: type,
          label: this.$t('Offer.TargetOptions')[type]
        }
      })
    },
    OfferDiscountTypeOptions() {
      return Object.keys(this.$t('Offer.ActivityTypeOptions')).map(type => {
        return {
          value: type,
          label: this.$t('Offer.ActivityTypeOptions')[type]
        }
      })
    },
    TargetGroupTypeOptions() {
      return Object.keys(this.$t('Offer.ActivityTargetGroupOptions')).map(type => {
        return {
          value: type,
          label: this.$t('Offer.ActivityTargetGroupOptions')[type]
        }
      })
    }
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList(),
        this.GetSinglePageList()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      const Data = {
        Taxonomy: 'Activity',
        Page: this.Pagination.Page,
        PerPage: this.Pagination.PerPage,
        Order: 'nto'
      }
      Object.keys(this.SearchFilter).forEach((param) => {
        switch (typeof this.SearchFilter[param]) {
          case 'object':
            if (Array.isArray(this.SearchFilter[param]) && this.SearchFilter[param].length > 0) {
              Data[param] = this.SearchFilter[param].map(item => item.value || item)
            }
            if (typeof this.SearchFilter[param].getMonth === 'function') {
              Data[param] = this.$dayjs(this.SearchFilter[param]).unix()
            }
            break
          case 'string':
          case 'number':
            if (this.SearchFilter[param] !== '') {
              Data[param] = this.SearchFilter[param]
            }
            break
        }
      })
      this.Loading = true
      this.FilterModel = false
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/manage/list',
          Data: Data
        }
      }).then(({data}) => {
        this.Loading = false
        this.List = data.Data.map(item => {
          if (item.Status === 'Y') {
            if (this.$dayjs().unix() > this.$dayjs(item.EndTime).unix()) {
              item.Status = 'E'
            } else if (this.$dayjs().unix() < this.$dayjs(item.StartTime).unix()) {
              item.Status = 'U'
            }
          }
          return item
        })
        this.Pagination = data.Pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetSinglePageList() {
      this.Loading = true
      this.PageList = [
        { label: this.$t('Offer.AllUsagePage'), value: 'ALL' },
        { label: this.$t('Offer.OfficialWeb'), value: 'OFFICIAL' }
      ]
      return this.$store.dispatch('InnerRequest', {
        url: '/page/shop/list',
        method: 'get',
        params: {
          page: 1,
          perPage: 80,
          order: 'nto'
        }
      }).then(({data}) => {
        this.Loading = false
        const SinglePage = data.list.map(item => {
          return {
            label: `${item.Name} (${item.Slug})`,
            value: item.Slug
          }
        })
        this.PageList = [
          ...this.PageList,
          ...SinglePage
        ]
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseID = this.List.map(data => {
          return data._id
        })
      } else {
        this.CheckAllValue = false
        this.ChooseID = []
      }
    },
    DeleteModal (ID) {
      this.ChooseID = [ID]
      this.AlertModel = true
    },
    Delete() {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/delete',
          Data: {
            Taxonomy: 'Activity',
            ID: this.ChooseID
          }
        }
      }).then(() => {
        this.Init()
        this.Submit = false
        this.AlertModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Offer/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Submit = false
        this.AlertModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Offer/DeleteFail') + err.msg,
          type: 'error'
        })
      })
    },
    OpenFilter(type) {
      this.FilterModel = true
      this.ShowFilter = type
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      this.SearchFilter[id].push(newTag.trim())
    },
    ResetFilter() {
      this.Submit = false
      this.AlertModel = false
      this.SearchFilter = {
        StartTime: '',
        EndTime: '',
        Name: '',
        Slug: [],
        'Offer.Target': [],
        'Offer.DiscountType': [],
        'UsagePage.Slug': [],
        'TargetGroup.Type': [],
        Status: ''
      }
      this.Init()
    }
  }
}
</script>

<style>
#CouponList thead th:first-child {
  text-align: center;
}
#CouponList tbody td:first-child {
  vertical-align: middle;
}
</style>
